import { useState } from 'react';
import cn from 'classnames';

import { Button, Icon } from '@dansk-metal/ui';
import { formatDate } from '@dansk-metal/utils/date';

import { Modal } from '@web/components/modal/modal';

import { TaskDto } from '@web/services/crm-api/rest';

import { getOverdueText } from './utils/get-overdue-text';
import { isTaskOverdue } from './utils/is-task-overdue';

import styles from './task.module.scss';

export interface TaskProps {
	task: TaskDto;
}

export function Task({ task }: TaskProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const isNotReady = task.status === 'unavailable' || task.status === 'pending';

	function handleOpenModal() {
		if (task.status === 'available' || task.status === 'completed') {
			setIsModalOpen(true);
		}
	}

	async function handleResolveTask() {
		if (task.action?.url) {
			window.open(`${task.action.url}?taskId=${task.taskId}`, '_blank');
		}
	}

	return (
		<>
			<Button unstyled className={cn(styles.task_container, { [styles.unclicable]: task.status !== 'available' })} onClick={handleOpenModal} >
				<div className={cn({ [styles.not_ready]: task.status !== 'available' })}>
					<Icon size={24} icon={task.status === 'completed' ? 'tick-alt' : 'unchecked'} />
				</div>
				<div>
					<p className={cn(styles.task_header, { [styles.not_ready]: isNotReady })}>
						{task.title}
					</p>
					{task.status === 'completed' && task.completedDate ? (
						<p>Afsluttet: {formatDate(task.completedDate, '22. feb 2022')}</p>
					) : (
						<p className={task.dueDate && isTaskOverdue(task.dueDate) ? styles.deadline_overdue : ''}>
							{getOverdueText(task.dueDate, isNotReady, task.availableDate)}
						</p>
					)}
				</div>
			</Button>
			<Modal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			>
				<div className={styles.modal_content}>
					<h3>{task.title}</h3>
					<p>
						Vær opmærksom på, at der kan gå
						<strong> op til 24 timer</strong>
						, fra du har afsluttet opgaven, til du kan se det på tjeklisten.
					</p>
					<Button icon={{ icon: 'arrow-right', size: 24 }} onClick={handleResolveTask}>Fortsæt</Button>
				</div>
			</Modal>
		</>
	);
}
