import Image from 'next/image';
import Link from 'next/link';
import cn from 'classnames';

import { Button, Icon } from '@dansk-metal/ui';

import { Aspect } from '@web/components/media/aspectRatios';
import { buildUrl } from '@web/components/qbank-image/loader';
import { QBankMediaDisplay } from '@web/components/qbank-media/qbank-media';
import { Theme } from '@web/components/theme/theme';

import { PublicationSearchItem } from '@web/services/umbraco/rest';
import { QBankMedia } from '@web/services/umbraco/types/basic/QBank';

import placeholderImage from '../../../public/assets/file-placeholder-img.png';

import styles from './publication-item.module.scss';

export type PublicationItemProps = {
	publication: PublicationSearchItem;
	extendedVersion?: boolean;
};

export function PublicationItem({ publication, extendedVersion }: PublicationItemProps) {
	const { url, publishDate, issuuURL, title, tags, file, name } = publication;

	function downloadFile(e: { preventDefault: () => void }) {
		e.preventDefault();
		if (file && file.mediaUrl) {
			window.open(file.mediaUrl);
		}
	}

	return (
		<Theme className={cn({ [styles.extended]: !!extendedVersion })}>
			<div className={styles.content}>
				{!issuuURL && !file?.filename && (
					<Button href={url} unstyled className={styles.content__image_container}>
						<div className={styles.placeholder_img_wrapper}>
							<Image
								src={placeholderImage}
								width={160}
								loader={() => ''}
								unoptimized={true}
								alt={'pladsholder'}
							/>
						</div>
					</Button>
				)}
				{!!file && file.filename && (
					<Button unstyled href={issuuURL || buildUrl(file.filename)} target="_blank" className={styles.content__image_container}>
						{file.extension === 'docx' || file.extension === 'xlsx' ? (
							<div className={styles.placeholder_img_wrapper}>
								<Image
									src={placeholderImage}
									width={160}
									loader={() => ''}
									unoptimized={true}
									alt={'pladsholder'}
								/>
							</div>
						) : (
							<QBankMediaDisplay className={styles.image} media={{ ...file, type: file.mediaType } as QBankMedia} aspectRatio={Aspect['1:1']} />
						)}
					</Button>
				)}
				<div className={styles.content__container}>
					<Link className={styles.unstyled_link} href={url}>
						<div className={styles.info_line}>
							<span className={styles.tag}>{tags ? tags[0] : ' '}</span>
							{publishDate && <span className={styles.date}>{publishDate}</span>}
						</div>

						<div className={styles.title__container}>
							{(title || name) && title ? (
								<h3 className={styles.content__headline} dangerouslySetInnerHTML={{ __html: title }} />
							) : (
								<h3 className={styles.content__headline}>{name}</h3>
							)}
						</div>
					</Link>
					{issuuURL && (
						<Button className={styles.issuuUrl} unstyled href={issuuURL} tabIndex={0} target="_blank">
							<Icon className={styles.download_icon} icon="book" size={24} />
							<p>Læs</p>
						</Button>
					)}
					{!issuuURL && file?.filename && (
						<Button
							unstyled
							target="_blank"
							className={styles.download}
							href={file && file.filename ? buildUrl(file.filename) : ''}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									downloadFile(e);
								}
							}}
							role="link"
							tabIndex={0}
						>
							<Icon className={styles.download_icon} icon="download" size={24} />
							Hent
						</Button>
					)}
				</div>
			</div>
		</Theme>
	);
}
